<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      query="&sort=-id"
      entity-name="文件"
      show-row-num
      hide-show-detail
      add-btn="添加文件"
      :columns="columns"
      :rules="rules"
      :search-key-type="searchKeyType"
      :before-edit="beforeEdit"
      :before-update="beforeUpdate"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入文件名称查询" allowClear></a-input>
        </a-form-model-item>
      </template>
      <template v-slot:edit="{ form }">
        <a-form-model-item label="文件名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入文件名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="文件内容" prop="content">
          <form-upload
            v-model="form.content"
            list-type="text"
            custom-accept=".jpg,.png,.jpeg,.doc,.docx,.excel,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            save-file-name
            @change="fileChange"
          ></form-upload>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <a class="txt-btn" @click.stop="download(item)">下载</a>
      </template>
    </data-list>
  </div>
</template>

<script>
import { clone, downLoadFile, getSuffix, isEmpty } from "../../../common/js/tool";
import formUpload from "../../../components/form-upload.vue";
export default {
  components: { formUpload },
  name: "FileNotice",
  data() {
    return {
      loading: false,
      url: "/admin/volunteer-files",
      searchKeyType: {
        name: 1,
      },
      columns: [
        { title: "文件名称", dataIndex: "name" },
        { title: "上传时间", dataIndex: "create_time" },
      ],
      rules: {
        name: [{ required: true, message: "请输入文件名称", trigger: "blur" }],
        content: [{ required: true, message: "请上传文件内容", trigger: "change" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  methods: {
    addVolunteer() {
      this.$router.push({ name: "MemberAdd" });
    },
    updateList() {
      this.dataList.updateList();
    },
    beforeEdit(form, fn) {
      if (form.content) {
        form.content = form.content?.split(",").map((url) => {
          return {
            name: form.name + getSuffix(url),
            url,
          };
        });
      }
      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (res.content) {
        res.content = res.content.map((item) => item.url).join(",");
      }
      fn(res);
    },
    getFileName(name) {
      let pos = name.lastIndexOf(".");
      if (pos > -1) {
        return name.substr(0, pos);
      } else {
        return name;
      }
    },
    fileChange(val) {
      if (val?.length > 0 && isEmpty(this.dataList.single?.name)) {
        this.dataList.single.name = this.getFileName(val[0].name);
      }
    },
    download(item) {
      downLoadFile(item.content, item.name);
    },
  },
};
</script>

<style lang="less" scoped>
.range-picker {
  text-align: left;
}
.query-switch {
  display: flex;
  padding: 12px;
}
.switch-item {
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    margin-left: 24px;
  }
  .switch-text {
    margin-left: 12px;
  }
}
</style>
